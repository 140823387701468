import * as React from 'react';
import {
  Grid,
  Button,
  TextField,
  Typography,
  InputLabel,
} from '@material-ui/core';
import {
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';
import {withFormik, Form} from "formik";
import * as Yup from 'yup';
import {useInformationContext} from "../../providers/InformationProvider";
import {formatInformationRequests} from "../../utils";

const useStyles = makeStyles((theme) => createStyles({
  mt1: {
    marginTop: theme.spacing(1),
  },
  cap: {
    textTransform: 'capitalize',
  },
}));

const FormInformationSubmit = (props) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
  } = props;
  const {item} = useInformationContext();
  const classes = useStyles();
  return (
    <React.Fragment>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography>You were asked to submit below information.</Typography>
            <Typography className={classes.cap}>{formatInformationRequests(item?.requests)}</Typography>
          </Grid>
          {
            item?.description?.trim() !== "" ?
            <Grid item xs={12} sm={12}>
              <InputLabel>Description</InputLabel>
              <TextField
                className={classes.mt1}
                fullWidth
                multiline
                minRows={6}
                variant="outlined"
                color="primary"
                value={item?.description}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid> : null
          }

          {
            item?.attachmentUrl ?
              <Grid item xs={12} sm={12}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href={`http://docs.google.com/gview?url=${item.attachmentUrl}`}
                  target="_blank" rel="noreferrer"
                >View Attachment</a>
              </Grid> : null
          }

          <Grid item xs={12} sm={12}>
            <InputLabel>Answer</InputLabel>
            <TextField
              className={classes.mt1}
              fullWidth
              multiline
              minRows={6}
              error={!!errors.answerDescription && touched.answerDescription}
              helperText={!!errors.answerDescription && touched.answerDescription && errors.answerDescription}
              autoComplete="description"
              value={values?.answerDescription}
              variant="outlined"
              onChange={e => setFieldValue('answerDescription', e.target.value)}
            />
          </Grid>

          <Grid
            item xs={12}
            style={{display: 'flex', justifyContent: 'flex-end'}}
          >
            <Button
              variant="contained"
              color="primary"
              type='submit'
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Form>
    </React.Fragment>
  );
}

const formSchema = () => {
  return Yup.object().shape({
    answerDescription: Yup.string()
      .required("Please fill in the description")
      .min(20, 'Please enter more than 20 letters')
      .max(5000, 'Please enter less than 5000 letters'),
  });
};
const defaultValues = {
  answerDescription: '',
};
const EnhancedForm = withFormik({
  mapPropsToValues: () => (defaultValues),
  validationSchema: () => Yup.lazy((values) => formSchema(values)),
  enableReinitialize: true,
  handleSubmit: async (values, {props}) => {
    const {handleReply, navigate} = props;
    handleReply(values)
      .then(() => {
        navigate("/dashboard");
      });
  },
})(FormInformationSubmit);

export default EnhancedForm;
