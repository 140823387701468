import axios from "axios";
import {getCookie} from "../utils";
import config from "../config";

export const instance = axios.create({
  baseURL: config.REACT_APP_API_BASE_PATH,
  timeout: 30000,
});

// Request interceptor for API calls
instance.interceptors.request.use(
  async config => {
    const token = getCookie('token');

    if (token) {
      config.headers = {
        'Authorization': `${token}`,
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error)
  });

// Add a response interceptor
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  if (response?.status === 401) { // if token expired
    // todo token expired exception
  } else {
    return response?.data;
  }
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export const login = (data) => {
  return new Promise((resolve, reject) => {
    instance.post('/api/auth/login', data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const register = (data) => {
  return new Promise((resolve, reject) => {
    instance.post('/api/auth/register', data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const forgotPassword = (data) => {
  return new Promise((resolve, reject) => {
    instance.post('/api/auth/forgot-password', data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const resetPassword = (data) => {
  return new Promise((resolve, reject) => {
    instance.post('/api/auth/reset-password', data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const verifyEmail = (token) => {
  return new Promise((resolve, reject) => {
    instance.get(`/api/auth/verify-email/${token}`)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const getTenants = () => {
  return new Promise((resolve, reject) => {
    instance.get('/api/tenant')
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
}

export const createTenant = (payload) => {
  return new Promise((resolve, reject) => {
    instance.post(`/api/tenant`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }})
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const deleteTenant = tenantId => {
  return new Promise((resolve, reject) => {
    instance.delete(`/api/tenant/${tenantId}`)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const getMe = () => {
  return new Promise((resolve, reject) => {
    instance.get('/api/user')
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
}

export const getInvoiceRequests = tenantId => {
  return new Promise((resolve, reject) => {
    instance.get(`/api/user/invoice/${tenantId}/request`)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
}

export const getMyReviews = tenantId => {
  return new Promise((resolve, reject) => {
    instance.get(`/api/user/invoice/${tenantId}/reviews`)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const getInvoiceRequestDetails = ({tenantId, id}) => {
  return new Promise((resolve, reject) => {
    instance.get(`/api/invoice/${tenantId}/${id}`)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const bulkCancelRequests = ({tenantId, ids}) => {
  return new Promise((resolve, reject) => {
    instance.post(`/api/invoice/${tenantId}/bulk-cancel`, {ids})
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const remindInvoiceRequest = ({tenantId, id, remindMode}) => {
  return new Promise((resolve, reject) => {
    instance.post(`/api/invoice/${tenantId}/remind/${id}/${remindMode}`)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const reviewInvoiceRequest = ({tenantId, id, action}) => {
  return new Promise((resolve, reject) => {
    instance.get(`/api/invoice/${tenantId}/review/${id}/${action}`)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const delegateInvoiceRequest = ({tenantId, id, data}) => {
  return new Promise((resolve, reject) => {
    instance.post(`/api/invoice/${tenantId}/delegate/${id}`, data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const requestInvoiceApproval = ({tenantId, data}) => {
  return new Promise((resolve, reject) => {
    instance.post(`/api/invoice/${tenantId}/request`, data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const inviteUserToTenant = ({tenantId, data}) => {
  return new Promise((resolve, reject) => {
    instance.post(`/api/tenant/${tenantId}/user`, data)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const getUsersUnderTenant = ({tenantId}) => {
  return new Promise((resolve, reject) => {
    instance.get(`/api/tenant/${tenantId}/user`)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const cancelInvoiceRequest = ({tenantId, id}) => {
  return new Promise((resolve, reject) => {
    instance.post(`/api/invoice/${tenantId}/cancel/${id}`)
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const bulkInvoiceReview = ({ids, action, tenantId}) => {
  return new Promise((resolve, reject) => {
    instance.post(`/api/invoice/${tenantId}/bulk-review/${action}`, {ids})
      .then(res => resolve(res))
      .catch(e => reject(e));
  });
};

export const uploadFile = (data) => {
  return new Promise((resolve, reject) => {
    instance.post('/api/upload', data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const getInformationRequests = (tenantId) => {
  return new Promise((resolve, reject) => {
    instance.get(`/api/user/information/${tenantId}/request`)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const getEnrollmentRequestsForMe = (tenantId) => {
  return new Promise((resolve, reject) => {
    instance.get(`/api/user/information/${tenantId}/reviews`)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const createEnrollmentRequest = ({tenantId, data}) => {
  return new Promise((resolve, reject) => {
    instance.post(`/api/information/${tenantId}/request`, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const replyEnrollmentRequest = ({id, data, tenantId}) => {
  return new Promise((resolve, reject) => {
    instance.post(`/api/information/${tenantId}/review/${id}`, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const deleteEnrollmentRequest = id => {
  return new Promise((resolve, reject) => {
    instance.delete(`/api/information-enrollment/${id}`)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const getMappings = (tenantId) => {
  return new Promise((resolve, reject) => {
    instance.get(`/api/mapping/${tenantId}`)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const createMapping = ({tenantId, data}) => {
  return new Promise((resolve, reject) => {
    instance.post(`/api/mapping/${tenantId}`, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const submitAuthCode = ({tenantId, data}) => {
  return new Promise((resolve, reject) => {
    instance.post(`/api/mapping/${tenantId}/token/authCode`, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};


export const getMsalConfig = (tenantId) => {
  return new Promise((resolve, reject) => {
    instance.get(`/api/mapping/${tenantId}/config`)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const deleteMapping = ({id, tenantId}) => {
  return new Promise((resolve, reject) => {
    instance.delete(`/api/mapping/${tenantId}/${id}`)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};

export const updateMapping = ({id, data, tenantId}) => {
  return new Promise((resolve, reject) => {
    instance.put(`/api/mapping/${tenantId}/${id}`, data)
      .then(res => {
        resolve(res);
      })
      .catch(e => {
        reject(e);
      })
  });
};
