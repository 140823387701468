import * as React from 'react';
import {createTenant, deleteTenant, getTenants, getUsersUnderTenant, inviteUserToTenant} from "../http";
import TenantCreateModal from "../views/pages/Administration/TenantCreateModal";
import ConfirmDialog from "../views/components/ConfirmDialog";
import InviteModal from "../views/pages/Administration/InviteModal";
import {useSnackbar} from "notistack";
import TenantViewModal from "../views/pages/Administration/TenantDetailsModal";
import {showErrorNotification} from "../utils";
import {useUIContext} from "./UIProvider";

const AdministrationContext = React.createContext(null);

export const AdministrationProvider = (
  {
    children,
  }) => {
  const defaultConfirmModal = {
    visible: false,
    title: '',
    content: '',
    mode: null, // delete, ..
  };
  const [selectedTenant, setSelectedTenant] = React.useState(null);
  const [tenantList, setTenantList] = React.useState([]);
  const [visibleCreateModal, setVisibleCreateModal] = React.useState(false);
  const [visibleInviteModal, setVisibleInviteModal] = React.useState(false);
  const [visibleViewModal, setVisibleViewModal] = React.useState(false);
  const [confirmModal, setConfirmModal] = React.useState(defaultConfirmModal);
  const {enqueueSnackbar} = useSnackbar();
  const {setLoading} = useUIContext();
  const showNotification = (msg, variant) => {
    enqueueSnackbar(msg, {variant, autoHideDuration: 3000});
  };

  React.useEffect(() => {
    let mounted = true;
    getTenants().then(res => {
      setTenantList(res);
    });

    return () => {
      mounted = false;
    };
  }, []);

  const handleTenantCreate = async data => {
    try {
      showNotification(`Please wait while we process your request`, 'info');
      setLoading(true);
      const tenant = await createTenant(data);
      setTenantList(prev => [...prev, tenant]);
      setVisibleCreateModal(false);
    } catch (e) {
      console.error('creating tenant error', e);
      showErrorNotification(e, showNotification);
    } finally {
      setLoading(false);
    }
  }

  const _deleteTenant = async () => {
    if (selectedTenant?.uuid) {
      try {
        setLoading(true);
        await deleteTenant(selectedTenant.uuid);
        setTenantList(prev => prev.filter(it => it.uuid !== selectedTenant.uuid));
        handleConfirmCancel();
      } catch (e) {
        console.error("delete tenant error", e);
        showErrorNotification(e, showNotification);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleConfirmOk = React.useCallback(async () => {
    switch (confirmModal.mode) {
      case 'delete':
        await _deleteTenant();
        break;
      default:
        console.log("confirm mode not registered");
    }
  }, [confirmModal, selectedTenant]);

  const handleConfirmCancel = () => {
    setConfirmModal(defaultConfirmModal);
  }

  const handleClickDelete = (item) => {
    setSelectedTenant(item);
    setConfirmModal({
      visible: true,
      title: 'Are you sure you want to delete this tenant?',
      content: '',
      mode: 'delete',
    });
  }

  const handleClickView = item => {
    setSelectedTenant(item);
    if (!item.uuid) return;

    setVisibleViewModal(true);

    getUsersUnderTenant({tenantId: item.uuid})
      .then(res => {
        const {members, pendingMembers} = res;
        let ret = [];
        members?.forEach(it => ret.push({
          role: it.role,
          accepted: true,
          email: it.tenantUser?.email,
        }));

        pendingMembers?.forEach(it => ret.push({
          role: it.role,
          accepted: false,
          email: it.email,
        }));

        ret = ret.sort((a, b) => {
          return a.role > b.role ? 1 :
            (
              a.role < b.role ? -1 :
                a.accepted ? -1 : b.accepted ? 1 : 0
            );
        });

        setSelectedTenant(prev => ({
          ...prev,
          members: ret,
        }));
      });
  }

  const handleCancelInvite = () => {
    setSelectedTenant(null);
    setVisibleInviteModal(false);
  }

  const handleClickInvite = item => {
    setSelectedTenant(item);
    setVisibleInviteModal(true);
  }

  const handleInvite = formData => {
    if (!selectedTenant?.uuid) return;
    inviteUserToTenant({
      tenantId: selectedTenant.uuid,
      data: [formData],
    }).then(res => {
      const {
        alreadyBelongs,
        pendingInvitations,
        invited,
      } = res;

      if (alreadyBelongs?.length > 0) {
        showNotification(`${alreadyBelongs.toString()} already member of the tenant`, 'info');
      }
      if (pendingInvitations?.length > 0) {
        showNotification(`${pendingInvitations.toString()} already received the invitation before`, 'info');
      }
      if (invited?.length > 0) {
        showNotification(`${invited.toString()} successfully invited!`, 'success');
      }
      handleCancelInvite();
    })
      .catch(e => {
        console.error("invite user to tenant error", e);
        showErrorNotification(e, showNotification);
      });
  }

  const providerValue = {
    tenantList,
    handleClickCreate: () => setVisibleCreateModal(true),
    handleClickDelete,
    handleClickView,
    handleClickInvite,
  };

  return (
    <AdministrationContext.Provider value={providerValue}>
      {children}
      <TenantCreateModal
        open={visibleCreateModal}
        handleCancel={() => setVisibleCreateModal(false)}
        handleOk={handleTenantCreate}
      />
      <InviteModal
        open={visibleInviteModal}
        handleOk={handleInvite}
        handleCancel={handleCancelInvite}
      />
      <ConfirmDialog
        open={confirmModal.visible}
        title={confirmModal.title}
        content={confirmModal.content}
        handleOk={handleConfirmOk}
        handleCancel={handleConfirmCancel}
      />
      <TenantViewModal
        open={visibleViewModal}
        list={selectedTenant?.members ?? []}
        handleCancel={() => setVisibleViewModal(false)}
        handleOk={() => setVisibleViewModal(false)}
      />
    </AdministrationContext.Provider>
  );
};

export const useAdministrationContext = () => {
  const context = React.useContext(AdministrationContext);
  if (!context) {
    throw new Error("useAdministrationContext must be used within AdministrationProvider");
  }
  return context;
};
