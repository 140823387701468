import axios from "axios";
import config from "../config";
import {INFORMATION_REQUEST_TYPES} from "../constant";

export const  setCookie = (cname, cvalue) => {
  const now = new Date();
  const time = now.getTime();
  const expireTime = time + (1000 * 3600 * 24 * 7);
  now.setTime(expireTime);
  document.cookie = `${cname}=${cvalue};domain=${config.REACT_APP_DOMAIN};expires=${now.toUTCString()};`;
}

export const  getCookie = cname => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export const showErrorNotification = (e, show) => {
  if (e.response?.status === 401) {
    show("Token expired, please login again!", "error");
  } else {
    show(e.response?.data?.message || "Something went wrong, please contact administrator!", "error");
  }
};

export const checkPhoneNumber = (phoneNumber) => {
  const protocol = window.location.protocol;
  const url = `${protocol}//${config.TWILIO_CHECK_ADDRESS}?phone=${phoneNumber}`;

  return new Promise((resolve, reject) => {
    axios.get(url)
      .then(res => resolve(res.data))
      .catch(e => reject(e));
  })
};

export const getParamFromUrl = key => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(key)?.replace(/ /g, '+');
}

export const formatDate = d => {
  return new Date(d).toLocaleString([], {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
}

export const formatInformationRequests = requests => {
  const arrRequests = requests?.split(",");
  if (arrRequests?.length > 0) {
    return arrRequests?.map(it => INFORMATION_REQUEST_TYPES[it]?.split("_").join(" ")?.toLowerCase())?.join(", ");
  } else {
    return '';
  }
}
