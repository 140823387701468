import * as React from 'react';
import {
  Grid,
  Paper,
  Typography,
  TextField,
  InputLabel,
  Button,
} from '@material-ui/core';
import {
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';
import clsx from "clsx";
import style from "./InformationReview.module.scss"
import {useTenantRoleContext} from "../../../providers/TenantRoleProvider";
import {useInformationContext} from "../../../providers/InformationProvider";
import {ORG_ROLE_ADMIN} from "../../../constant";
import {useNavigate, useParams} from "react-router-dom";
import {formatInformationRequests} from "../../../utils";

const useStyles = makeStyles((theme) => createStyles({
  paper: {
    padding: theme.spacing(3),
    width: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      width: "auto",
    },
  },
  mt1: {
    marginTop: theme.spacing(1),
  },
  cap: {
    textTransform: 'capitalize',
  },
}));

export default function InformationReview() {
  const classes = useStyles();
  const {item, setId} = useInformationContext();
  const {tenant} = useTenantRoleContext();
  const {id} = useParams();
  const isAdmin = (tenant?.role === ORG_ROLE_ADMIN);
  const navigate = useNavigate();
  React.useEffect(() => {
    setId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className={clsx(style.Wrapper)}>
      <Paper
        variant="outlined"
        className={classes.paper}
      >
        <Typography variant="h6" align="center">
          Information
        </Typography>
        <React.Fragment>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Typography className={classes.cap}>{formatInformationRequests(item?.requests)}</Typography>
            </Grid>
            {
              item?.description?.trim() !== "" ?
              <Grid item xs={12} sm={12}>
                <InputLabel>Description</InputLabel>
                <TextField
                  className={classes.mt1}
                  fullWidth
                  multiline
                  minRows={6}
                  variant="outlined"
                  color="primary"
                  value={item?.description}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid> : null
            }

            {
              item?.attachmentUrl ?
                <Grid item xs={12} sm={12}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href={`http://docs.google.com/gview?url=${item.attachmentUrl}`}
                    target="_blank" rel="noreferrer"
                  >View Attachment</a>
                </Grid> : null
            }

            <Grid item xs={12} sm={12}>
              <InputLabel>Answer</InputLabel>
              <TextField
                className={classes.mt1}
                fullWidth
                multiline
                minRows={6}
                autoComplete="description"
                variant="outlined"
                value={item?.answerDescription}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            {
              (item && isAdmin) ? <Grid
                item xs={12}
                style={{display: 'flex', justifyContent: 'flex-end'}}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type='submit'
                  onClick={() => navigate(`/information-request/${item.mode}`)}
                >
                  Create Another
                </Button>
              </Grid> : null
            }
          </Grid>
        </React.Fragment>
      </Paper>
    </div>
  );
};
