import * as React from 'react';
import {
  createEnrollmentRequest, deleteEnrollmentRequest,
  getInformationRequests, getEnrollmentRequestsForMe, replyEnrollmentRequest,
} from "../http";
import {useSnackbar} from 'notistack';
import ConfirmDialog from "../views/components/ConfirmDialog";
import {checkPhoneNumber, showErrorNotification} from "../utils";
import {useTenantRoleContext} from "./TenantRoleProvider";
import * as constant from "../constant";
import {useUIContext} from "./UIProvider";
import {INFORMATION_REQUEST_TYPES} from "../constant";

const InformationContext = React.createContext(null);

export const InformationProvider = (
  {
    children,
  }) => {
  const [id, setId] = React.useState(null);
  const [item, setItem] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const {tenant} = useTenantRoleContext();
  const {enqueueSnackbar} = useSnackbar();
  const [mounted, setMounted] = React.useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = React.useState(false);
  const {setLoading} = useUIContext();
  const showNotification = (msg, variant) => {
    enqueueSnackbar(msg, {variant, autoHideDuration: 3000});
  };
  React.useEffect(() => {
    setMounted(true);
    initialize().then();
    return () => {
      setMounted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  const initialize = async () => {
    if (tenant) {
      try {
        setLoading(true);
        const {role, uuid} = tenant;
        let res = [];
        if (role === constant.ORG_ROLE_ADMIN) {
          res = await getInformationRequests(uuid);
        } else if (role === constant.ORG_ROLE_USER) {
          res = await getEnrollmentRequestsForMe(uuid);
        } else {
          // todo error handling
        }
        if (mounted) setItems(res ?? []);
      } catch (e) {
        showErrorNotification(e, showNotification);
      } finally {
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    if (tenant?.uuid && id) {
      setItem(items?.find(it => it.id?.toString() === id?.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tenant, items]);

  const handleCreate = data => {
    return new Promise(async (resolve, reject) => {
      if (!tenant?.uuid)
        reject("tenant id null");

      try {
        setLoading(true);
        if (data.phone) {
          const twilioResponse = await checkPhoneNumber(`+1 ${data.phone}`);
          if (!twilioResponse.success) {
            showNotification('Please check if the phone number is correct!', 'error');
            reject("phone number invalid");
          }
        }
        let requests = [
          data?.invoice ? INFORMATION_REQUEST_TYPES.INVOICE : null,
          data?.accountNumber ? INFORMATION_REQUEST_TYPES.ACCOUNT_NUMBER : null,
          data?.loginCredentials ? INFORMATION_REQUEST_TYPES.LOGIN_CREDENTIALS : null,
          data?.endorsement ? INFORMATION_REQUEST_TYPES.ENDORSEMENT : null,
          data?.unresponsive ? INFORMATION_REQUEST_TYPES.UNRESPONSIVE : null,
          data?.directPointContact ? INFORMATION_REQUEST_TYPES.DIRECT_POINT_CONTACT : null,
          data?.other ? INFORMATION_REQUEST_TYPES.OTHER : null,
        ];
        requests = requests.filter(it => it);
        const payload = {
          mode: data.mode,
          email: data.email,
          phone: data.phone,
          description: data.description,
          requests: requests.toString(),
          attachmentUrl: data.attachmentUrl,
        };
        const res = await createEnrollmentRequest({tenantId: tenant.uuid, data: payload});
        setItems(prev => [res, ...prev]);
        showNotification("Successfully requested!", "success");
        resolve();
      } catch (e) {
        console.error("request information error", e);
        showErrorNotification(e, showNotification);
        reject(e);
      } finally {
        setLoading(false);
      }
    })
  }

  const handleReply = (payload) => {
    return new Promise(async (resolve, reject) => {
      if (!tenant?.uuid) reject("tenant uuid null");
      if (!item?.id) {
        showNotification('Please select item!', 'error');
        reject("item id null")
      }
      try {
        setLoading(true);
        const entity = await replyEnrollmentRequest({
          id: item.id,
          data: payload,
          tenantId: tenant.uuid,
        });
        setItems(prev => prev.map(it => it.id?.toString() !== item.id?.toString() ? it : entity));
        showNotification("Successfully replied!", "success");
        resolve();
      } catch (e) {
        console.error("reply to information request error", e);
        showErrorNotification(e, showNotification);
        reject(e);
      } finally {
        setLoading(false);
      }
    });
  }

  // const getEnrollmentRequest = id => {
  //   const item = (items?.find(it => it?.id?.toString() === id?.toString()));
  //   if (!item) {
  //     // showNotification("Item not found", "error");
  //   } else {
  //     setItem(item);
  //   }
  // }
  // const handleDelete = () => {
  //   if (item?.id) {
  //     setSubmitting(true);
  //     deleteEnrollmentRequest(item.id)
  //       .then(() => {
  //         setItems(prev => prev.filter(it => it.id?.toString() !== item.id?.toString()));
  //       })
  //       .catch(e => {
  //         showErrorNotification(e, showNotification);
  //       })
  //       .finally(() => {
  //         setSubmitting(false);
  //         setVisibleDeleteModal(false);
  //       });
  //   }
  // }

  const sortedItems = React.useMemo(() => {
    return items?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }, [items]);

  const providerValue = {
    item,
    items: sortedItems,
    setItems,
    setId,
    setVisibleDeleteModal,
    handleCreate,
    handleReply,
  };

  return (
    <InformationContext.Provider value={providerValue}>
      {children}
      {/*<ConfirmDialog
        open={visibleDeleteModal}
        handleOk={handleDelete}
        handleCancel={() => setVisibleDeleteModal(false)}
        title={"Delete?"}
        content={"Are you sure you want to delete?"}
      />*/}
    </InformationContext.Provider>
  );
};

export const useInformationContext = () => {
  const context = React.useContext(InformationContext);
  if (!context) {
    throw new Error("useInformationContext must be used within InformationProvider");
  }
  return context;
};
