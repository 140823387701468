import React from "react";
import {useAuthContext} from "../../providers/AuthProvider";
import config from "../../config";

function RequireAuth({children}) {
  const {loggedIn} = useAuthContext();
  React.useEffect(() => {
    if (!loggedIn) {
      const previousUrl = escape(encodeURI(window.location.href));
      window.location.href = `${config.REACT_APP_PROTOCOL}://${config.REACT_APP_BASE_CHILD_DOMAIN}.${config.REACT_APP_DOMAIN}:${config.REACT_APP_PORT}/login?from=${previousUrl}`;
    }
  }, [loggedIn]);

  if (loggedIn) {
    return children;
  } else {
    return null;
  }
}

export default RequireAuth;
