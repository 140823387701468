import * as React from "react";
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import './App.scss';
import RequireAuth from "./views/wrappers/RequireAuth";
import {isMobile} from "react-device-detect";
import {useSnackbar} from 'notistack';
import MainLayout from "./views/layouts/MainLayout";
import {AdministrationProvider} from "./providers/AdministrationProvider";
import InformationRequest from "./views/pages/InformationRequest";
import InformationReview from "./views/pages/InformationReview";
import InformationSubmit from "./views/pages/InformationSubmit";
import {MsalProvider} from "./providers/MsalProvider";

const MainLayoutV2 = React.lazy(() => import("./views/layouts/MainLayoutV2"));
const Login = React.lazy(() => import("./views/pages/Login"));
const Register = React.lazy(() => import("./views/pages/Register"));
const ForgotPassword = React.lazy(() => import("./views/pages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("./views/pages/ResetPassword"));
const EmailVerify = React.lazy(() => import("./views/pages/EmailVerify"));
const Board = React.lazy(() => import("./views/pages/Board"));
const Administration = React.lazy(() => import("./views/pages/Administration"));
const Dashboard = React.lazy(() => import("./views/pages/Dashboard"));
const InvoiceConfirm = React.lazy(() => import("./views/pages/InvoiceConfirm"));
const InvoiceRequest = React.lazy(() => import("./views/pages/InvoiceRequest"));
const InvoiceReview = React.lazy(() => import("./views/pages/InvoiceReview"));
const Msal = React.lazy(() => import("./views/pages/Msal"));
const MsalVerification = React.lazy(() => import("./views/pages/Msal/MsalVerification"));

function App() {
  const [menuCollapse, setMenuCollapse] = React.useState(isMobile);
  const {enqueueSnackbar} = useSnackbar();
  const showNotification = (msg, variant) => {
    enqueueSnackbar(msg, {variant, autoHideDuration: 3000});
  };

  return (
    <BrowserRouter basename="/">
      <React.Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route
            path="/login"
            element={
              <Login showNotification={showNotification}/>
            }
          />

          <Route
            path="/signup"
            element={
              <Register showNotification={showNotification}/>
            }
          />

          <Route
            path="/forgot-password"
            element={
              <ForgotPassword showNotification={showNotification}/>
            }
          />

          <Route
            path="/reset-password"
            element={
              <ResetPassword showNotification={showNotification}/>
            }
          />

          <Route
            path="/email-verify/:token"
            element={
              <EmailVerify showNotification={showNotification}/>
            }
          />

          <Route
            path="/register"
            element={
              <Login showNotification={showNotification}/>
            }
          />

          <Route
            path="/board"
            element={
              <RequireAuth>
                <MainLayout menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}>
                  <Board/>
                </MainLayout>
              </RequireAuth>
            }
          />

          <Route
            path="/administration"
            element={
              <RequireAuth>
                <MainLayout menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}>
                  <AdministrationProvider>
                    <Administration/>
                  </AdministrationProvider>
                </MainLayout>
              </RequireAuth>
            }
          />

          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <MainLayoutV2 menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}>
                  <Dashboard/>
                </MainLayoutV2>
              </RequireAuth>
            }
          />

          <Route
            path="/invoice-confirm/:id"
            element={
              <RequireAuth>
                <MainLayoutV2 menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}>
                  <InvoiceConfirm/>
                </MainLayoutV2>
              </RequireAuth>
            }
          />

          <Route
            path="/invoice-review/:id"
            element={
              <RequireAuth>
                <MainLayoutV2 menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}>
                  <InvoiceReview/>
                </MainLayoutV2>
              </RequireAuth>
            }
          />

          <Route
            path="/invoice-request"
            element={
              <RequireAuth>
                <MainLayoutV2 menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}>
                  <InvoiceRequest/>
                </MainLayoutV2>
              </RequireAuth>
            }
          />

          <Route
            path="/information-request/:mode"
            element={
              <RequireAuth>
                <MainLayoutV2 menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}>
                  <InformationRequest/>
                </MainLayoutV2>
              </RequireAuth>
            }
          />

          <Route
            path="/information-review/:id"
            element={
              <RequireAuth>
                <MainLayoutV2 menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}>
                  <InformationReview/>
                </MainLayoutV2>
              </RequireAuth>
            }
          />

          <Route
            path="/information-submit/:id"
            element={
              <RequireAuth>
                <MainLayoutV2 menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}>
                  <InformationSubmit/>
                </MainLayoutV2>
              </RequireAuth>
            }
          />

          <Route
            path="/mappings"
            element={
              <RequireAuth>
                <MainLayoutV2 menuCollapse={menuCollapse} setMenuCollapse={setMenuCollapse}>
                  <MsalProvider>
                    <Msal/>
                  </MsalProvider>
                </MainLayoutV2>
              </RequireAuth>
            }
          />

          <Route
            path="/msal"
            element={
              <RequireAuth>
                <MsalVerification/>
              </RequireAuth>
            }
          />

          <Route
            path="*"
            element={<Navigate to="/board" replace/>}
          />
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
