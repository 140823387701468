import * as React from 'react';
import {
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import {
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';
import {useUIContext} from "../../../providers/UIProvider";

const useStyles = makeStyles((theme) => createStyles({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Loading = () => {
  const classes = useStyles();
  const {loading} = useUIContext();

  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color="inherit"/>
    </Backdrop>
  )
};

export default Loading;
