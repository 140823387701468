import * as React from 'react';
import {useTenantRoleContext} from "./TenantRoleProvider";
import {ORG_ROLE_ADMIN} from "../constant";
import {createMapping, deleteMapping, getMappings, getMsalConfig, updateMapping} from "../http";
import {useUIContext} from "./UIProvider";
import {useSnackbar} from "notistack";
import {showErrorNotification} from "../utils";

const MsalContenxt = React.createContext(null);

export const MsalProvider = (
  {
    children,
  }) => {
  const {tenant} = useTenantRoleContext();
  const [items, setItems] = React.useState([]);
  const [msalConfig, setMsalConfig] = React.useState(null);
  const {setLoading} = useUIContext();
  const isAdmin = React.useMemo(() => tenant.role === ORG_ROLE_ADMIN, [tenant]);
  const {enqueueSnackbar} = useSnackbar();
  const showNotification = (msg, variant) => {
    enqueueSnackbar(msg, {variant, autoHideDuration: 3000});
  };
  React.useEffect(() => {
    let mounted = true;
    if (isAdmin && tenant.uuid) {
      setLoading(true);
      getMappings(tenant.uuid)
        .then(res => {
          if (mounted) setItems(res);
        })
        .catch(e => {
          showErrorNotification(e, showNotification);
        })
        .finally(() => {
          if (mounted) setLoading(false);
        });
      getMsalConfig(tenant.uuid)
        .then(res => {
          setMsalConfig(res);
        })
        .catch(e => {
          showErrorNotification(e, showNotification);
        })
    }
    return () => {
      mounted = false;
    }
  }, [tenant, isAdmin]);

  const handleCreate = React.useCallback(data => {
    return new Promise((resolve, reject) => {
      if (!tenant?.uuid) return reject("tenant not found");
      else {
        setLoading(true);
        createMapping({tenantId: tenant.uuid, data})
          .then(res => {
            setItems(prev => [...prev, res]);
            showNotification("Successfully created!", 'success');
            resolve();
          })
          .catch(e => {
            showErrorNotification(e, showNotification);
            reject(e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    })
  }, [tenant]);

  const handleUpdate = React.useCallback((id, data) => {
    return new Promise((resolve, reject) => {
      if (!tenant?.uuid) return reject("tenant not found");
      else {
        setLoading(true);
        updateMapping({tenantId: tenant.uuid, data, id})
          .then(res => {
            setItems(prev => prev.map(it => it.id === res.id ? res : it));
            showNotification("Successfully updated!", 'success');
            resolve();
          })
          .catch(e => {
            showErrorNotification(e, showNotification);
            reject(e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    })
  }, [tenant]);

  const handleDelete = React.useCallback((id) => {
    return new Promise((resolve, reject) => {
      if (!tenant?.uuid) return reject("tenant not found");
      else {
        setLoading(true);
        deleteMapping({tenantId: tenant.uuid, id})
          .then(() => {
            setItems(prev => prev.filter(it => it.id?.toString() !== id?.toString()));
            showNotification("Successfully deleted!", 'success');
            resolve();
          })
          .catch(e => {
            showErrorNotification(e, showNotification);
            reject(e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    })
  }, [tenant]);

  const providerValue = {
    items,
    msalConfig,
    handleCreate,
    handleUpdate,
    handleDelete,
  };

  return (
    <MsalContenxt.Provider value={providerValue}>
      {children}
    </MsalContenxt.Provider>
  );
};

export const useMsalContenxt = () => {
  const context = React.useContext(MsalContenxt);
  if (!context) {
    throw new Error("useMsalContenxt must be used within MsalProvider");
  }
  return context;
};
