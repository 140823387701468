import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {List, ListItem, ListItemText, Avatar} from '@material-ui/core';
import {
  Done,
} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
}));

const TenantViewModal = (
  {
    open = false,
    list = [],
    handleCancel = () => {},
    handleOk = () => {},
  }) => {
  const classes = useStyles();

  React.useEffect(() => {

  }, [open]);

  return (
    <Dialog
      open={open}
      maxWidth={"md"}
      onClose={handleCancel}
    >
        <DialogTitle>Members</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please check invited members. (Accepted and Pending)
          </DialogContentText>

          <List className={classes.root}>
            {
              list?.map((item, index) => (
                <ListItem key={`member-${index}`}>
                  <ListItemText
                    primary={item.email}
                    secondary={item.role}
                  />
                  {
                    item.accepted &&
                    <Avatar>
                      <Done />
                    </Avatar>
                  }
                </ListItem>
              ))
            }
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleOk}>
            Ok
          </Button>
        </DialogActions>
    </Dialog>
  );
}

export default TenantViewModal;
