import * as React from 'react';
import {getCookie, setCookie} from "../utils";

const AuthContext = React.createContext(null);

export const AuthProvider = (
  {
    children,
  }) => {
  const initialToken = getCookie('token');
  const [token, setToken] = React.useState(initialToken);
  const loggedIn = React.useMemo(() => !["", "undefined", undefined, null].includes(token), [token]);
  const _setToken = token => {
    setCookie("token", token);
    setToken(token);
  }
  const providerValue = {
    token,
    loggedIn,
    _setToken,
  };

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within AuthProvider");
  }
  return context;
};
