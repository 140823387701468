import * as React from 'react';
import {
  Paper,
  Typography,
} from '@material-ui/core';
import {
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import style from "./InformationSubmit.module.scss";
import clsx from "clsx";
import {useInformationContext} from "../../../providers/InformationProvider";
import FormInformationSubmit from "../../forms/FormInformationSubmit";
import {useNavigate, useParams} from "react-router-dom";
import {INFORMATION_TYPE_ENROLLMENT} from "../../../constant";

const useStyles = makeStyles((theme) => createStyles({
  paper: {
    padding: theme.spacing(3),
    width: '600px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      width: "auto",
    },
  },
}));

export default function InformationSubmit() {
  const {id} = useParams();
  const {
    setId,
    item,
    handleReply,
  } = useInformationContext();
  React.useEffect(() => {
    setId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const showNotification = (msg, variant) => {
    enqueueSnackbar(msg, { variant, autoHideDuration: 3000 });
  };
  const navigate = useNavigate();

  return (
    <div className={clsx(style.Wrapper)}>
      <Paper
        variant="outlined"
        className={classes.paper}
      >
        <Typography variant="h6" align="center">
          Reply to {item?.mode === INFORMATION_TYPE_ENROLLMENT ? "Enrollment" : "Payment"} Request
        </Typography>
        <React.Fragment>
          <FormInformationSubmit
            showNotification={showNotification}
            handleReply={handleReply}
            navigate={navigate}
          />
        </React.Fragment>
      </Paper>
    </div>
  );
};
