import React from "react";

import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

import "react-pro-sidebar/dist/css/styles.css";
import clsx from "clsx";
import style from "./Sidebar.module.scss";
import "./sidebar.scss";
import {useLocation, useNavigate} from "react-router-dom";
import {setCookie} from "../../../utils";
import config from "../../../config";
import {useTenantRoleContext} from "../../../providers/TenantRoleProvider";

const SideBar = (
  {
    menuCollapse,
    setMenuCollapse,
    menuItems = [],
  }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // create a custom function that will change menucollapse state from false to true and true to false
  const menuIconClick = () => {
    // condition checking to change state from true to false and vice versa
    // eslint-disable-next-line no-unused-expressions
    menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
  };
  const logout = () => {
    setCookie("token", "");
    setCookie('system-role', "");
    setCookie("tenants", "");
    window.location.href = `${config.REACT_APP_PROTOCOL}://${config.REACT_APP_BASE_CHILD_DOMAIN}.${config.REACT_APP_DOMAIN}:${config.REACT_APP_PORT}/login`;
  }
  const {tenant} = useTenantRoleContext();
  const logo = tenant?.logo ? `${config.REACT_APP_API_BASE_PATH}/logo/${tenant.logo}` : '/image/sidebar/big-logo.png';

  return (
    <>
      <div id="header" className={clsx(style.SideBar)}>
        {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar collapsed={menuCollapse}>
          <SidebarHeader>
            <div className={clsx(style.LogoText)}>
              {menuCollapse ? (
                null
                /*<img
                  className={style.SmallLogo}
                  alt=" small-logo"
                  src={logo}
                  // src="/image/sidebar/small-logo.png"
                />*/
              ) : (
                <img
                  className={clsx(style.BigLogo)}
                  alt=" big-logo"
                  src={logo}
                  // src="/image/sidebar/big-logo.png"
                />
              )
              }
            </div>
            <div
              className={'close-menu'}
              onClick={menuIconClick}
            >
              {menuCollapse ? (
                <img alt="show" src="/image/sidebar/show.svg"/>
              ) : (
                <img alt="hide" src="/image/sidebar/hide.svg"/>
              )}
            </div>
          </SidebarHeader>
          <SidebarContent>
            <Menu iconShape="square">
              {
                menuItems?.map((menu, index) => (
                  <MenuItem
                    active={location?.pathname?.includes(menu.uri)}
                    key={`menu-${index}`}
                    icon={<img alt={`${menu.title} icon`} src={menu.image}/>}
                    onClick={() => {
                      if (menu.uri) navigate(menu.uri);
                    }}
                  >
                    {menu.title}
                  </MenuItem>
                ))
              }
            </Menu>
          </SidebarContent>
          <SidebarFooter>
            <Menu iconShape="square">
              <MenuItem
                icon={<img alt="logout" src="/image/sidebar/out.svg"/>}
                onClick={logout}
              >Logout</MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
};

export default SideBar;