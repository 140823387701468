export const ORG_ROLE_ADMIN = 'admin';
export const ORG_ROLE_USER = 'user';
export const SYSTEM_ROLE_ADMIN = 'super_admin';
export const SYSTEM_ROLE_ORG_MEMBER = 'organization_member';
export const INVOICE_REVIEW_APPROVE = 'approve';
export const INVOICE_REVIEW_DENY = 'deny';
export const INVOICE_STATUS_PENDING = 'pending';
export const INVOICE_STATUS_APPROVED = 'approved';
export const INVOICE_STATUS_DENIED = 'denied';
export const INVOICE_STATUS_CANCELED = 'canceled';
export const INFORMATION_REQUEST_TYPES = {
  INVOICE: 'INVOICE',
  ACCOUNT_NUMBER: 'ACCOUNT_NUMBER',
  LOGIN_CREDENTIALS:'LOGIN_CREDENTIALS',
  ENDORSEMENT: 'ENDORSEMENT',
  UNRESPONSIVE: 'UNRESPONSIVE',
  CONFIRMATION: 'CONFIRMATION',
  DIRECT_POINT_CONTACT: 'DIRECT_POINT_CONTACT',
  OTHER: 'OTHER',
};
export const INFORMATION_TYPE_ENROLLMENT = "enrollment";
export const INFORMATION_TYPE_PAYMENT = "payment";
export const INVOICE = "invoice";
export const INFORMATION_STATUS_REPLIED = "replied";
export const INFORMATION_STATUS_PENDING = "pending";
