import * as React from 'react';
import {useAuthContext} from "./AuthProvider";
import {getMe} from "../http";
import {getCookie, setCookie} from "../utils";
import {SYSTEM_ROLE_ADMIN} from "../constant";

const UserContext = React.createContext(null);

export const UserProvider = (
  {
    children,
  }) => {
  const {token, loggedIn} = useAuthContext();

  const initialTenantsStr = getCookie('tenants');
  const initialTenants = initialTenantsStr ? JSON.parse(initialTenantsStr) : [];
  const [tenants, setTenants] = React.useState(initialTenants);

  const initialRole = getCookie('system-role');
  const [systemRole, setSystemRole] = React.useState(initialRole);

  const [me, setMe] = React.useState(null);

  React.useEffect(() => {
    let mounted = true;
    if (loggedIn && token) {
      getMe()
        .then(res => {
          if (mounted) setMe(res);
        });
    }

    return () => {
      mounted = false;
    };
  }, [token, loggedIn]);
  React.useEffect(() => {
    if (me?.tenants) {
      const ret = me.tenants.map(it => ({
        uuid: it["tenant.uuid"],
        name: it["tenant.db_name"],
        role: it.role,
        logo: it["tenant.logo"],
      }));
      setCookie("tenants", JSON.stringify(ret));
      setTenants(ret);
    }
    if (me?.role) {
      setCookie('system-role', me.role);
      setSystemRole(me.role);
    }
  }, [me]);
  const isSystemAdmin = React.useMemo(() => systemRole === SYSTEM_ROLE_ADMIN, [systemRole]);

  const providerValue = {
    me,
    systemRole,
    tenants,
    isSystemAdmin,
  };

  return (
    <UserContext.Provider value={providerValue}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext must be used within UserProvider");
  }
  return context;
};
