import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Grid} from '@material-ui/core';
import DialogContentText from '@material-ui/core/DialogContentText';
import {Form, withFormik} from "formik";
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import * as Yup from 'yup';
import clsx from "clsx";
import style from "./TenantCreateModal.module.scss";
import defaultLogo from "../../../assets/images/default-logo.png";
import {ChromePicker} from 'react-color'

const TenantCreateModal = (
  {
    open = false,
    handleCancel = () => {},
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  }) => {
  const imageRef = React.useRef(null);
  const inputRef = React.useRef(null);
  const [color, setColor] = React.useState('#fff');

  React.useEffect(() => {
    if (open) {
      resetForm({
        values: {tenantName: ''},
      });
    }
  }, [open]);

  const handleImagePick = (e) => {
    const [file] = e.target.files;
    if (file) {
      imageRef.current.src = URL.createObjectURL(file);
      setFieldValue("logo", file);
    } else {
      imageRef.current.src = defaultLogo;
    }
  }

  return (
    <Dialog
      open={open}
      maxWidth={"md"}
      onClose={handleCancel}
    >
      <Form>
        <DialogTitle>Create Tenant</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please input the tenant name. (alphabet, numbers, hyphen only) <br/>
            Customizing main color and logo for the tenant will come soon.
          </DialogContentText>
          <Grid container>
            <TextField
              autoFocus
              margin="dense"
              id="tenantName"
              label="Tenant name"
              type="text"
              error={!!errors.tenantName && touched.tenantName}
              helperText={!!errors.tenantName && touched.tenantName && errors.tenantName}
              fullWidth
              value={values.tenantName}
              onChange={e => setFieldValue("tenantName", e.target.value)}
            />
          </Grid>

          <Grid container direction="row" justifyContent="space-around" alignItems="center">
            <Grid item>
              <input ref={inputRef} type="file" accept="image/*" onChange={handleImagePick} style={{display: 'none'}} />
              <img
                ref={imageRef}
                src={defaultLogo}
                alt="image here"
                className={clsx(style.Logo)}
              />
            </Grid>

            <Grid item>
              <IconButton variant="outlined" color="primary" onClick={() => inputRef.current.click()}>
                <AddCircleIcon/>
              </IconButton>
            </Grid>
          </Grid>

          <Grid container justifyContent="center">
            <ChromePicker
              color={color}
              onChangeComplete={(e) => setColor(e.hex)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Ok
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

const formSchema = () => {
  return Yup.object().shape({
    tenantName: Yup.string()
      .test(
        'is-valid',
        'Please enter valid tenant name',
        function (value) {
          const re = /[^a-zA-Z0-9\-]/;
          return !re.test(value);
        }
      )
      .required('Please enter a tenant name'),
  });
};
const EnhancedForm = withFormik({
  mapPropsToValues: () => ({
    tenantName: '',
  }),
  validationSchema: () => Yup.lazy((values) => formSchema(values)),
  enableReinitialize: true,
  handleSubmit: async (values, {props}) => {
    const {handleOk} = props;
    const form = new FormData();
    form.append('organization', values.tenantName);
    if (values.logo) {
      form.append('logo', values.logo);
    }
    handleOk(form);
  },
})(TenantCreateModal);

export default EnhancedForm;
