import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AuthProvider} from "./providers/AuthProvider";
import {UserProvider} from "./providers/UserProvider";
import {TenantRoleProvider} from "./providers/TenantRoleProvider";
import {InvoiceProvider} from "./providers/InvoiceProvider";
import {SnackbarProvider} from "notistack";
import Loading from "./views/components/Backdrop";
import {UIProvider} from "./providers/UIProvider";
import {InformationProvider} from "./providers/InformationProvider";
/*todo check what React.StrictMode does*/
ReactDOM.render(
  <UIProvider>
    <SnackbarProvider maxSnack={3}>
      <AuthProvider>
        <UserProvider>
          <TenantRoleProvider>
            <InvoiceProvider>
              <InformationProvider>
                <App/>
                <Loading/>
              </InformationProvider>
            </InvoiceProvider>
          </TenantRoleProvider>
        </UserProvider>
      </AuthProvider>
    </SnackbarProvider>
  </UIProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
