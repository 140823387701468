import React from "react";
import SideBar from '../../components/Sidebar'
import style from "./MainLayout.module.scss"
import clsx from "clsx";
import {isMobile} from "react-device-detect";
import {useUserContext} from "../../../providers/UserProvider";

const MainLayout = (
  {
    children,
    setMenuCollapse,
    menuCollapse,
  }) => {
  const {isSystemAdmin} = useUserContext();
  const menuItems = React.useMemo(() => {
    const items = [{
      image: '/image/sidebar/item-dashboard.svg',
      uri: '/board',
      title: 'Board',
    }];
    if (isSystemAdmin) {
      items.push({
        image: '/image/sidebar/item-dashboard.svg',
        uri: '/administration',
        title: 'Administration',
      });
    }
    return items;
  }, [isSystemAdmin]);
  // create initial menuCollapse state using useState hook
  const paddingLeft = React.useMemo(() => {
    return (menuCollapse || isMobile) ? "80px" : "220px";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuCollapse, isMobile]);
  return (
    <div className={clsx(style.Wrapper)}>
      <SideBar
        menuCollapse={menuCollapse}
        setMenuCollapse={setMenuCollapse}
        menuItems={menuItems}
      />
      <div className={clsx(style.Content)} style={{paddingLeft: paddingLeft}}>
        {
          children
        }
      </div>
    </div>
  )
}

export default MainLayout;
