import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {withFormik, Form} from "formik";
import * as Yup from 'yup';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {ORG_ROLE_ADMIN, ORG_ROLE_USER} from "../../../constant";

const InviteModal = (
  {
    open = false,
    handleCancel = () => {},
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  }) => {

  React.useEffect(() => {
    if (open) {
      resetForm({
        values: {tenantName: ''},
      });
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      maxWidth={"md"}
      onClose={handleCancel}
    >
      <Form>
        <DialogTitle>Invite to tenant</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the user's email and role. The user will receive notification.
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="tenantName"
            label="Email"
            type="text"
            error={!!errors.email && touched.email}
            helperText={!!errors.email && touched.email && errors.email}
            value={values.email}
            fullWidth
            onChange={e => setFieldValue("email", e.target.value)}
          />

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-helper-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={values?.role}
              onChange={e => setFieldValue('role', e.target.value)}
            >
              <MenuItem value={ORG_ROLE_ADMIN}>Admin</MenuItem>
              <MenuItem value={ORG_ROLE_USER}>User</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Ok
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

const formSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email')
      .required('Please enter an email'),
    role: Yup.string()
      .test(
        'is-valid',
        'Please select the role',
        v => {
          return [ORG_ROLE_USER, ORG_ROLE_ADMIN].includes(v);
        }
      )
      .required('Please select the role'),
  });
};
const EnhancedForm = withFormik({
  mapPropsToValues: () => ({
    email: '',
    role: ORG_ROLE_USER,
  }),
  validationSchema: () => Yup.lazy((values) => formSchema(values)),
  enableReinitialize: true,
  handleSubmit: async (values, {props}) => {
    const {handleOk} = props;
    handleOk(values);
  },
})(InviteModal);

export default EnhancedForm;
