import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withFormik, Form} from "formik";
import * as Yup from 'yup';

function FormDialog(
  {
    title,
    open,
    handleClose,
    values,
    errors,
    touched,
    setFieldValue,
  }) {

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <Form>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To delegate this invoice approval request, please enter the email address here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            fullWidth
            error={!!errors?.email && touched?.email}
            helperText={!!errors?.email && touched?.email && errors?.email}
            value={values?.email}
            onChange={e => setFieldValue('email', e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" type="button">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Ok
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
}

const formSchema = () => {
  return Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email')
      .required('Please enter an email'),
  });
};

const defaultValue = {
  email: '',
};

const EnhancedForm = withFormik({
  mapPropsToValues: () => (defaultValue),
  validationSchema: formSchema,
  enableReinitialize: true,
  handleSubmit: async (values, {props}) => {
    props.handleSubmit(values);
  },
})(FormDialog);

export default EnhancedForm;