import * as React from 'react';

const UIContext = React.createContext(null);

export const UIProvider = (
  {
    children,
  }) => {
  const [loading, setLoading] = React.useState(false);
  const providerValue = {
    loading,
    setLoading,
  };

  return (
    <UIContext.Provider value={providerValue}>
      {children}
    </UIContext.Provider>
  );
};

export const useUIContext = () => {
  const context = React.useContext(UIContext);
  if (!context) {
    throw new Error("useUIContext must be used within UIProvider");
  }
  return context;
};
