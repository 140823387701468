import * as React from 'react';
import {
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from '@material-ui/core';
import {
  makeStyles,
  createStyles,
} from '@material-ui/core/styles';
import InputMask from 'react-input-mask';
import {withFormik, Form} from "formik";
import * as Yup from 'yup';
import {DropzoneArea} from 'material-ui-dropzone';
import {showErrorNotification} from "../../utils";
import {uploadFile} from "../../http";
import {useUIContext} from "../../providers/UIProvider";

const useStyles = makeStyles((theme) => createStyles({
  mt1: {
    marginTop: theme.spacing(1),
  },
  cap: {
    textTransform: 'capitalize',
  },
}));

const FormInformationRequest = (props) => {
  const {
    values,
    errors,
    touched,
    setFieldValue,
    showNotification,
  } = props;
  const classes = useStyles();
  const {setLoading} = useUIContext();

  const handleUpload = async files => {
    if (files[0]) {
      const originName = files[0].name;
      const payload = new FormData();
      payload.append('file', files[0], originName);

      try {
        setLoading(true);
        const response = await uploadFile(payload);
        if (response.success) {
          setFieldValue('attachmentUrl', response.name);
        }
      } catch (e) {
        showErrorNotification(e, showNotification);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              id="email"
              name="email"
              label="Email"
              fullWidth
              error={!!errors.email && touched.email}
              helperText={!!errors.email && touched.email && errors.email}
              autoComplete="email"
              variant="standard"
              value={values?.email}
              onChange={e => setFieldValue('email', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="standard"
              fullWidth
            >
              <InputMask
                mask="(999) 999-9999"
                value={values?.phone}
                onChange={e => setFieldValue('phone', e.target.value)}
                maskChar=" "
              >
                {() => <TextField
                  label="Phone Number"
                  fullWidth
                  error={errors.phone && touched.phone}
                  helperText={!!errors.phone && touched.phone && errors.phone}
                  placeholder={'(407) 456-2349'}
                />}
              </InputMask>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl error={errors.formValid && touched.formValid}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.invoice}
                      onChange={e => setFieldValue('invoice', e.target.checked)}
                    />}
                  label="Invoice"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.accountNumber}
                      onChange={e => setFieldValue('accountNumber', e.target.checked)}
                    />}
                  label="Account Number"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.loginCredentials}
                      onChange={e => setFieldValue('loginCredentials', e.target.checked)}
                    />}
                  label="Login Credentials"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.endorsement}
                      onChange={e => setFieldValue('endorsement', e.target.checked)}
                    />}
                  label="Endorsement"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.unresponsive}
                      onChange={e => setFieldValue('unresponsive', e.target.checked)}
                    />}
                  label="Unresponsive"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.confirmation}
                      onChange={e => setFieldValue('confirmation', e.target.checked)}
                    />}
                  label="Confirmation Needed"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.directPointContact}
                      onChange={e => setFieldValue('directPointContact', e.target.checked)}
                    />}
                  label="Direct Point of Contact"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.other}
                      onChange={e => setFieldValue('other', e.target.checked)}
                    />}
                  label="Other - See Notes"
                />
              </FormGroup>
              {
                errors.formValid && touched.formValid &&
                <FormHelperText>{!!errors.formValid && touched.formValid && errors.formValid}</FormHelperText>
              }
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <InputLabel>Description</InputLabel>
            <TextField
              className={classes.mt1}
              fullWidth
              multiline
              minRows={6}
              error={!!errors.description && touched.description}
              helperText={!!errors.description && touched.description && errors.description}
              autoComplete="description"
              value={values?.description}
              variant="outlined"
              onChange={e => setFieldValue('description', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <DropzoneArea
              filesLimit={1}
              dropzoneText="Drag & drop an attachment or click to pick"
              acceptedFiles={['application/pdf']}
              onChange={handleUpload}
            />
          </Grid>
          <Grid
            item xs={12}
            style={{display: 'flex', justifyContent: 'flex-end'}}
          >
            <Button
              variant="contained"
              color="primary"
              type='submit'
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Form>
    </React.Fragment>
  );
}

const formSchema = (values) => {
  const {
    other,
    invoice,
    accountNumber,
    loginCredentials,
    endorsement,
    unresponsive,
    confirmation,
    directPointContact,
  } = values;
  return Yup.object().shape({
    email: Yup.string()
      .email('Please enter a valid email')
      .max(1024, 'Please enter less than 1024 letters')
      .required('Please enter an email'),
    phone: Yup.string(),
    invoice: Yup.boolean(),
    accountNumber: Yup.boolean(),
    loginCredentials: Yup.boolean(),
    endorsement: Yup.boolean(),
    unresponsive: Yup.boolean(),
    confirmation: Yup.boolean(),
    directPointContact: Yup.boolean(),
    other: Yup.boolean(),
    description: other ?
      Yup.string()
        .required("Please fill in the description")
        .min(20, 'Please enter more than 20 letters')
        .max(5000, 'Please enter less than 5000 letters') :
      Yup.string()
        .min(20, 'Please enter more than 20 letters')
        .max(5000, 'Please enter less than 5000 letters'),
    formValid: Yup.string()
      .test(
        'is-valid',
        'Please select at least one item',
        function () {
          return invoice || accountNumber || loginCredentials || endorsement || unresponsive || confirmation || directPointContact || other;
        }
      )
  });
};
const defaultValues = {
  email: '',
  phone: '',
  invoice: false,
  accountNumber: false,
  loginCredentials: false,
  endorsement: false,
  unresponsive: false,
  confirmation: false,
  directPointContact: false,
  other: false,
  description: '',
  formValid: '',
  attachmentUrl: '',
}
const EnhancedForm = withFormik({
  mapPropsToValues: () => (defaultValues),
  validationSchema: () => Yup.lazy((values) => formSchema(values)),
  enableReinitialize: true,
  handleSubmit: async (values, {props}) => {
    const {_handleSubmit, mode, navigate} = props;
    const data = JSON.parse(JSON.stringify(values));
    data["mode"] = mode;
    _handleSubmit(data).then(() => navigate("/dashboard"));
  },
})(FormInformationRequest);

export default EnhancedForm;
