import * as React from 'react';
import {useTenantRoleContext} from "./TenantRoleProvider";
import * as constant from "../constant";
import {
  bulkCancelRequests, bulkInvoiceReview, cancelInvoiceRequest, delegateInvoiceRequest,
  getInvoiceRequestDetails,
  getInvoiceRequests,
  getMyReviews,
  remindInvoiceRequest, requestInvoiceApproval, reviewInvoiceRequest,
} from "../http";
import ConfirmDialog from "../views/components/ConfirmDialog";
import {useSnackbar} from "notistack";
import {showErrorNotification} from "../utils";
import {
  INVOICE_REVIEW_APPROVE,
  INVOICE_REVIEW_DENY,
  INVOICE_STATUS_APPROVED,
  INVOICE_STATUS_PENDING
} from "../constant";
import DelegateModal from "../views/components/DelegateModal";
import {useUIContext} from "./UIProvider";

const InvoiceContext = React.createContext(null);
// fixme store approve, deny, cancel as constant values
export const InvoiceProvider = (
  {
    children,
  }) => {
  const {setLoading} = useUIContext();
  const {tenant} = useTenantRoleContext();
  const [invoices, setInvoices] = React.useState([]);
  const [item, setItem] = React.useState(null);
  const [id, setId] = React.useState(null);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const {enqueueSnackbar} = useSnackbar();
  const showNotification = (msg, variant) => {
    enqueueSnackbar(msg, {variant, autoHideDuration: 3000});
  };
  const [confirmModal, setConfirmModal] = React.useState({
    visible: false,
    title: '',
    content: '',
    mode: null, // bulkCancel, approve, deny, bulkApprove, bulkDeny
  });
  const [mounted, setMounted] = React.useState(false);
  const [visibleDelegateModal, setVisibleDelegateModal] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
    loadInvoiceRequests();
    return () => {
      setMounted(false);
    };
  }, [tenant]);

  const loadInvoiceRequests = () => {
    if (tenant) {
      const {role, uuid} = tenant;
      let promise = null;
      if (role === constant.ORG_ROLE_ADMIN) {
        promise = getInvoiceRequests(uuid)
      } else if (role === constant.ORG_ROLE_USER) {
        promise = getMyReviews(uuid)
      } else {
        // todo error handling
      }
      if (promise) {
        promise.then(res => {
          if (mounted)
            setInvoices(res ?? []);
        })
          .catch(e => {
            // todo show error notification
            console.error("get invoice requests error", e);
          });
      }
    } else {
      setInvoices([]);
    }
  };

  React.useEffect(() => {
    if (tenant?.uuid && id) {
      setLoading(true);
      getInvoiceRequestDetails({tenantId: tenant.uuid, id})
        .then(response => {
          setItem(prevState => ({...prevState, ...response}));
        })
        .catch(error => {
          console.error("get invoice request detail error", error);
          showErrorNotification(error, showNotification);
          // todo error handling
          setItem(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, tenant]);

  const topCheckboxChecked = React.useMemo(() => {
    if (selectedItems?.length > 0) {
      return !(invoices.some(it => it.status === INVOICE_STATUS_APPROVED && !(selectedItems?.some(ele => ele?.toString() === it.id?.toString()))));
    } else {
      return false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const setTopCheckbox = flag => {
    if (flag) {
      const ids = invoices?.filter(it => it.status === INVOICE_STATUS_PENDING)?.map(it => it.id?.toString());
      setSelectedItems(ids ?? []);
    } else {
      setSelectedItems([]);
    }
  }

  const bulkAction = (actionMode) => () => {
    let title, mode = null;
    switch (actionMode) {
      case 'cancel':
        title = 'Are you sure you want to cancel these items?';
        mode = 'bulkCancel';
        break;
      case 'deny':
        title = 'Are you sure you want to deny these items?';
        mode = 'bulkDeny';
        break;
      case 'approve':
        title = 'Are you sure you want to approve these items?';
        mode = 'bulkApprove';
        break;
      default:
        console.log("bulk action mode not registered");
    }
    if (mode)
      setConfirmModal({
        visible: true,
        title,
        content: '',
        mode,
      });
  }

  const handleConfirmOk = React.useCallback(async () => {
    const handleBulkCancel = async () => {
      if (selectedItems?.length > 0) {
        try {
          setLoading(true);
          await bulkCancelRequests({tenantId: tenant.uuid, ids: selectedItems});
          showNotification('Successfully canceled!', 'success');
          handleConfirmCancel();
          setSelectedItems([]);
        } catch (e) {
          showErrorNotification(e, showNotification);
        } finally {
          setLoading(false);
          loadInvoiceRequests();
        }
      }
    }

    const handleBulkReview = async action => {
      if (selectedItems?.length > 0) {
        try {
          setLoading(true);
          await bulkInvoiceReview({tenantId: tenant.uuid, ids: selectedItems, action: action});
          showNotification('Successfully processed!', 'success');
          handleConfirmCancel();
          setSelectedItems([]);
        } catch (e) {
          showErrorNotification(e, showNotification);
        } finally {
          setLoading(false);
          loadInvoiceRequests();
        }
      }
    }

    const _handleReview = async () => {
      if (item?.id) {
        try {
          setLoading(true);
          const updatedItem = await reviewInvoiceRequest({tenantId: tenant.uuid, action: confirmModal.mode, id: item.id});
          if (confirmModal.mode === INVOICE_REVIEW_APPROVE) {
            showNotification("Successfully approved!", 'success');
          } else if (confirmModal.mode === INVOICE_REVIEW_DENY) {
            showNotification("Successfully denied!", 'success');
          }
          handleConfirmCancel();
          setItem(updatedItem);
          setInvoices(prev => prev.map(it => it.id?.toString() === updatedItem?.id?.toString() ? updatedItem : it));
        } catch (e) {
          showErrorNotification(e, showNotification);
        } finally {
          setLoading(false);
        }
      }
    }

    const _handleCancel = async () => {
      if (!item?.id || !tenant.uuid) return;

      try {
        setLoading(true);
        const response = await cancelInvoiceRequest({tenantId: tenant.uuid, id: item.id});
        showNotification("Successfully canceled!", 'success');
        setInvoices(prev => prev.map(it => it.id === response.id ? response : it));
        setItem(response);
        handleConfirmCancel();
      } catch (e) {
        showErrorNotification(e, showNotification);
      } finally {
        setLoading(false);
      }
    }

    switch (confirmModal.mode) {
      case "cancel":
        await _handleCancel();
        break;
      case "bulkCancel":
        await handleBulkCancel();
        break;
      case "bulkApprove":
        await handleBulkReview(INVOICE_REVIEW_APPROVE);
        break;
      case "bulkDeny":
        await handleBulkReview(INVOICE_REVIEW_DENY);
        break;
      case INVOICE_REVIEW_DENY:
      case INVOICE_REVIEW_APPROVE:
        await _handleReview();
        break;
      default:
        console.log("action type not registered");
    }
  }, [confirmModal]);

  const handleConfirmCancel = () => {
    setConfirmModal({
      visible: false, title: '', content: '', mode: null,
    });
  }

  const notify = async remindMode => {
    if (!(id && tenant.uuid)) {
      return;
    }
    try {
      setLoading(true);
      await remindInvoiceRequest({tenantId: tenant.uuid, id, remindMode});
      showNotification('Remind sent successfully', 'success');
    } catch (e) {
      showErrorNotification(e, showNotification);
    } finally {
      setLoading(false);
    }
  };

  const handleReview = mode => {
    let title = null;
    switch (mode) {
      case INVOICE_REVIEW_APPROVE:
        title = "Are you sure you want to approve this request?"
        break;
      case INVOICE_REVIEW_DENY:
        title = "Are you sure you want to deny this request?"
        break;
      default:
        console.log("action not registered");
    }
    if (title) {
      setConfirmModal({
        visible: true,
        title,
        mode: mode,
        content: null,
      })
    }
  }

  const delegateRequest = async data => {
    if (!item?.id)
      return;

    try {
      setLoading(true);
      await delegateInvoiceRequest({tenantId: tenant.uuid, id: item.id, data});
      showNotification('Successfully delegated!', 'success');
    } catch (e) {
      showErrorNotification(e, showNotification);
    } finally {
      setLoading(false);
      setVisibleDelegateModal(false);
    }
  };

  const handleCancel = () => {
    setConfirmModal({
      visible: true,
      title: 'Are you sure you want to cancel this invoice approval request?',
      mode: 'cancel',
      content: null,
    });
  }

  const handleCreateInvoiceRequest = async ({tenantId, data}) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await requestInvoiceApproval({tenantId, data});
        setInvoices(prev => [...prev, response]);
        resolve(response);
      } catch (e) {
        console.error("create invoice request error", e);
        reject(e);
      }
    })
  }

  const sortedInvoices = React.useMemo(() => {
    return invoices?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
  }, [invoices]);

  const providerValue = {
    invoices: sortedInvoices,
    item,
    setId,
    selectedItems,
    setSelectedItems,
    topCheckboxChecked,
    setTopCheckbox,
    bulkAction,
    notify,
    handleReview,
    handleCreateInvoiceRequest,
    handleCancel,
    handleDelegate: () => setVisibleDelegateModal(true),
  };

  return (
    <InvoiceContext.Provider value={providerValue}>
      <ConfirmDialog
        open={confirmModal.visible}
        title={confirmModal.title}
        content={confirmModal.content}
        handleOk={handleConfirmOk}
        handleCancel={handleConfirmCancel}
      />
      <DelegateModal
        open={visibleDelegateModal}
        title={'Delegate'}
        handleClose={() => setVisibleDelegateModal(false)}
        handleSubmit={delegateRequest}
      />
      {children}
    </InvoiceContext.Provider>
  );
};

export const useInvoiceContext = () => {
  const context = React.useContext(InvoiceContext);
  if (!context) {
    throw new Error("useInvoiceContext must be used within InvoiceProvider");
  }
  return context;
};
