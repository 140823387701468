import * as React from 'react';
import {useUserContext} from "./UserProvider";
import config from "../config";

const TenantRoleContext = React.createContext(null);

export const TenantRoleProvider = (
  {
    children,
  }) => {
  const {tenants} = useUserContext();
  const [tenant, setTenant] = React.useState();

  React.useEffect(() => {
    const subdomain = (new URL(window.location.href)).hostname.replace(`.${config.REACT_APP_DOMAIN}`,'');
    const meOnTenant = tenants?.find(it => it.name?.toLowerCase() === subdomain?.toLowerCase());
    let mounted = true;

    if (meOnTenant) {
      if (mounted) setTenant(meOnTenant);
    } else {
      if (mounted) setTenant(null);
      if (subdomain !== config.REACT_APP_BASE_CHILD_DOMAIN) {
        window.location.href = `${config.REACT_APP_PROTOCOL}://${config.REACT_APP_BASE_CHILD_DOMAIN}.${config.REACT_APP_DOMAIN}:${config.REACT_APP_PORT}/board`;
      }
    }

    return () => {
      mounted = false;
    };
  }, []);

  const providerValue = {
    tenant,
  };

  return (
    <TenantRoleContext.Provider value={providerValue}>
      {children}
    </TenantRoleContext.Provider>
  );
};

export const useTenantRoleContext = () => {
  const context = React.useContext(TenantRoleContext);
  if (!context) {
    throw new Error("useTenantRoleContext must be used within TenantRoleProvider");
  }
  return context;
};
